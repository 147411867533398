import React from 'react';
import { Container } from '../components/layout';
import { Flex, Grid, Image, PseudoBox, Spinner, Text } from '@chakra-ui/core';
import Logo from '../image/logo512.png';
import { getTranslation } from '../utils/getTranslation';
import { getSystemLanguage } from '../utils/getLanguage';
import QRCode from 'qrcode.react';
import { isEmpty } from '../components/utilities/is-empty';
import { useFetch } from '../components/use-fetch';
import { GridFieldItem } from '../components/grid-field-item';
import { SymbolLine } from '../components/symbol-line';
import { getDateReadFormat } from '../components/utilities/date-formatting';

const TYPE_COMPLETED_COLOR = {
  'false' : {
    colorBg: 'orange.100',
    colorFont: 'orange.800',
  },
  'true' : {
    colorBg: 'green.100',
    colorFont: 'green.800',
  }
}

export const Vaccination = () => {
  const lang = getSystemLanguage();
  const codeParam = window.location.search;
  const {
    data
  } = useFetch(`/json/vaccination/${codeParam}`);
  const completed = data?.complited;

  return (
    <Container>
      <Flex flexDirection="column" alignItems="center" mb={6}>
        <Image src={Logo} width="54px" height="54px" mb={2} alt=""/>
        <PseudoBox lineHeight="1.5" textAlign="center">
          <Text fontWeight="bold" fontSize="1.2rem">{getTranslation('pageTitleVaccination', lang)}</Text>
        </PseudoBox>
      </Flex>
      {!isEmpty(data) ? (
        <>
          <PseudoBox borderRadius={6} borderWidth={1} padding="20px 20px 5px 20px">
            <Flex justifyContent="center" padding="20px 10px 30px">
              <QRCode
                size={200}
                fgColor={completed ? '#276749' : '#ED8936'}
                value={window.location.href}
              />
            </Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="50px"
              borderRadius={6}
              backgroundColor={TYPE_COMPLETED_COLOR[completed].colorBg}
              color={TYPE_COMPLETED_COLOR[completed].colorFont}
              mb={2}
            >
              <PseudoBox textAlign="center">
                <Text
                  fontSize="0.9rem"
                  textTransform="uppercase"
                  fontWeight="700"
                >
                  {isEmpty(data) ?
                    getTranslation('resultNull', lang)
                    : (completed ?
                      getTranslation('vaccinationCompletedTrue', lang)
                      : getTranslation('vaccinationCompletedFalse', lang))}
                </Text>
              </PseudoBox>
            </Flex>
            <Grid>
              <PseudoBox padding="12px 0">
                <Text fontSize="0.9rem" color="gray.400" mb="2px">{getTranslation('vaccinationDate', lang)}</Text>
                <PseudoBox>{getDateReadFormat(data.vaccinationDate)}</PseudoBox>
              </PseudoBox>
              <GridFieldItem label={getTranslation('fieldPassport', lang)}>
                <SymbolLine>{data.passport}</SymbolLine>
              </GridFieldItem>
              <GridFieldItem label={getTranslation('vaccinationName', lang)}>
                {data.vaccine?.name[lang]}
              </GridFieldItem>
              <GridFieldItem label={getTranslation('vaccinationPhases', lang)}>
                {data.vaccine?.phases}
              </GridFieldItem>
              {data.doses && (
                <PseudoBox>
                  {data.doses.map((item, index) => (
                    <PseudoBox mt="20px">
                      <Text mb="10px" fontWeight="semibold" fontSize="1.2rem">Доза {item.dose}</Text>
                      <GridFieldItem borderTopWidth={0} label={getTranslation('dosesHospital', lang)}>
                        {item.hospital}
                      </GridFieldItem>
                      <GridFieldItem label={getTranslation('dosesName', lang)}>
                        {item.name}
                      </GridFieldItem>
                      <GridFieldItem label={getTranslation('dosesSeries', lang)}>
                        {item.series}
                      </GridFieldItem>
                      <GridFieldItem label={getTranslation('dosesDate', lang)}>
                        {getDateReadFormat(item.vaccinationDate)}
                      </GridFieldItem>
                    </PseudoBox>
                  ))}
                </PseudoBox>
              )}
            </Grid>
          </PseudoBox>
          <PseudoBox textAlign="center" mt={4} fontSize="0.9rem" padding="0 20px" color="gray.400">
            {getTranslation('footerInfo', lang)} <br/> https://check.nocovid.su
          </PseudoBox>
        </>
      ) : (
        <Flex height="150px" align="center" justify="center">
          <Spinner color="blue.500" />
        </Flex>
      )}
    </Container>
  );
};

