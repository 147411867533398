import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../components/use-fetch';
import { Alert, Spinner, Flex, Grid, PseudoBox, Text, Image } from '@chakra-ui/core';
import { isEmpty } from '../components/utilities/is-empty';
import moment from 'moment';
import { GridFieldItem } from '../components/grid-field-item';
import QRCode from 'qrcode.react';
import Logo from '../image/logo512.png';
import { SymbolLine } from '../components/symbol-line';
import { getDateReadFormat } from '../components/utilities/date-formatting';
import { Container } from '../components/layout';
import { getTranslation } from '../utils/getTranslation';
import { getLanguage } from '../utils/getLanguage';
import { LanguageSwitcher } from '../components/language-switcher';

export const TYPE_RESULT_COLOR = {
    '1' : {
        colorBg: 'red.100',
        colorFont: 'red.800',
    },
    '0' : {
        colorBg: 'green.100',
        colorFont: 'green.800',
    }
}

const getId = (id) => {
    if (id.length === 47) {
        return id
    } else {
        return id.slice(0, 47)
    }
}

const getLangFromTestId = (id) => {
    if (id.length === 49) {
        return id.slice(47, 49)
    }
    return null;
}

export const Check = () => {
    const { id } = useParams();
    const { langUrl } = useParams();
    const testId = getId(id);
    const [lang, setLang] = useState(getLanguage(langUrl, getLangFromTestId(id)));
    const testDate = moment.unix(testId.slice(-10)).format('DD.MM.YYYY HH:mm');
    const passport = testId.slice(-14, -10);
    const testResult = testId.slice(-15, -14);
    const testResultBol = testResult === '1';
    const {
        data
    } = useFetch(`/json/${testId}`);
    return (
        <Container>
            <Flex flexDirection="column" alignItems="center" mb={6}>
                <Image src={Logo} width="54px" height="54px" mb={2} alt=""/>
                <PseudoBox lineHeight="1.5" textAlign="center">
                    <Text fontWeight="bold" fontSize="1.2rem">{getTranslation('pageTitle', lang)}</Text>
                    <Text fontSize="0.86rem">{getTranslation('pageSubTitle', lang)}</Text>
                </PseudoBox>
                {!langUrl && (
                    <LanguageSwitcher
                        active={lang}
                        mt={5}
                        onClick={e => setLang(e)}
                    />
                )}
            </Flex>
            {!isEmpty(data) ? (
                data.success ? (
                    <>
                        <PseudoBox borderRadius={6} borderWidth={1} padding="20px 20px 5px 20px">
                            <Flex justifyContent="center" padding="20px 10px 30px">
                                <QRCode
                                    fgColor={testResultBol ? '#9B2C2C' : '#276749'}
                                    value={window.location.href}
                                />
                            </Flex>
                            <Flex
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                height="50px"
                                borderRadius={6}
                                backgroundColor={TYPE_RESULT_COLOR[testResult].colorBg}
                                color={TYPE_RESULT_COLOR[testResult].colorFont}
                                mb={2}
                            >
                                <PseudoBox textAlign="center">
                                    <Text
                                        fontSize="0.9rem"
                                        textTransform="uppercase"
                                        fontWeight="700"
                                    >
                                        {isEmpty(data) ?
                                            getTranslation('resultNull', lang)
                                            : (testResultBol ?
                                                getTranslation('resultTrue', lang)
                                                : getTranslation('resultFalse', lang))}
                                    </Text>
                                </PseudoBox>
                            </Flex>
                            <Grid>
                                <PseudoBox padding="12px 0">
                                    <Text fontSize="0.9rem" color="gray.400" mb="2px">{getTranslation('fieldTestDate', lang)}</Text>
                                    <PseudoBox>{testDate}</PseudoBox>
                                </PseudoBox>
                                <GridFieldItem label={getTranslation('fieldPassport', lang)}>
                                    <SymbolLine>{passport}</SymbolLine>
                                </GridFieldItem>
                                {data.insuranceNumber && (
                                    <GridFieldItem label={getTranslation('fieldInsuranceNumber', lang)}>
                                        {data.insuranceNumber}
                                        {data.insuranceValidUntil && (
                                            <Text fontSize="0.8rem" color="blue.400">{`${getTranslation('fieldInsuranceDate', lang)}: ${getDateReadFormat(data.insuranceValidUntil)}`}</Text>
                                        )}
                                    </GridFieldItem>
                                )}
                                <GridFieldItem label={getTranslation('fieldLaboratoryName', lang)}>{data.laboratory && data.laboratory.title?.[lang]}</GridFieldItem>
                                <GridFieldItem label={getTranslation('fieldLaboratoryTelephone', lang)}>{data.laboratory && data.laboratory.telephone}</GridFieldItem>
                                <GridFieldItem label={getTranslation('fieldLaboratoryAddress', lang)}>{data.laboratory && data.laboratory.address?.[lang]}</GridFieldItem>
                            </Grid>
                        </PseudoBox>
                        <PseudoBox textAlign="center" mt={4} fontSize="0.9rem" padding="0 20px" color="gray.400">
                            {getTranslation('footerInfo', lang)} <br/> https://check.nocovid.su
                        </PseudoBox>
                    </>
                ) : (
                    <>
                        <Alert
                            status="error"
                            borderRadius={5}
                            alignItems="center"
                            flexDirection="column"
                            textAlign="center"
                            lineHeight="1.2"
                        >
                            <Text>{getTranslation('checkError', lang)}</Text>
                        </Alert>
                        <PseudoBox textAlign="center" mt={4} fontSize="0.9rem" padding="0 20px" color="gray.400">
                            {getTranslation('footerInfo', lang)} <br/> https://check.nocovid.su
                        </PseudoBox>
                    </>
                )
            ) : (
                <Flex height="150px" align="center" justify="center">
                    <Spinner color="blue.500" />
                </Flex>
            )}
        </Container>
    )
};
