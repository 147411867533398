import React from 'react';
import { Container } from '../components/layout';
import { Flex, Grid, Image, PseudoBox, Spinner, Text } from '@chakra-ui/core';
import Logo from '../image/logo512.png';
import { getTranslation } from '../utils/getTranslation';
import { getSystemLanguage } from '../utils/getLanguage';
import QRCode from 'qrcode.react';
import { useFetch } from '../components/use-fetch';
import { GridFieldItem } from '../components/grid-field-item';
import { SymbolLine } from '../components/symbol-line';
import { getDateReadFormat } from '../components/utilities/date-formatting';
import { isEmpty } from 'lodash';

export const VaccinationId = () => {
  const lang = getSystemLanguage();
  const codeParam = window.location.search;
  const {
    data
  } = useFetch(`/json/vaccination/id/${codeParam}`);
  const completed = true;

  const getTitle = () => {
    if (!isEmpty(data?.vaccinationDate)) {
      return getTranslation('vaccination', lang)
    }
    if (!isEmpty(data?.recoveredDate)) {
      return getTranslation('recoveredPersonCode', lang)
    }
    return getTranslation('certificateCovid', lang)
  }

  return (
    <Container>
      <Flex flexDirection="column" alignItems="center" mb={6}>
        <Image src={Logo} width="54px" height="54px" mb={2} alt=""/>
        <PseudoBox lineHeight="1.5" textAlign="center">
          <Text fontWeight="bold" fontSize="1.2rem">{getTranslation('pageTitleCertificate', lang)}</Text>
        </PseudoBox>
      </Flex>
      {!isEmpty(data) ? (
        <>
          <PseudoBox borderRadius={6} borderWidth={1} padding="20px 20px 5px 20px">
            <Flex justifyContent="center" padding="20px 10px 30px">
              <QRCode
                size={200}
                fgColor={completed ? '#276749' : '#ED8936'}
                value={window.location.href}
              />
            </Flex>
            <Grid>
              {isEmpty(data?.directVaccinationInfo?.vaccinationStaffName) && (
                <Text mb={3}>{getTitle()}</Text>
              )}
              {!isEmpty(data?.expiredAt) && (
                <PseudoBox padding="12px 0">
                  <Text fontSize="0.9rem" color="gray.400" mb="2px">{getTranslation('vaccinationExpiredAt', lang)}</Text>
                  <PseudoBox>{getDateReadFormat(data.expiredAt)}</PseudoBox>
                </PseudoBox>
              )}
              {!isEmpty(data?.vaccinationDate) && (
                <PseudoBox padding="12px 0">
                  <Text fontSize="0.9rem" color="gray.400" mb="2px">{getTranslation('vaccinationDate', lang)}</Text>
                  <PseudoBox>{getDateReadFormat(data.vaccinationDate)}</PseudoBox>
                </PseudoBox>
              )}
              {!isEmpty(data?.passport) && (
                <GridFieldItem label={getTranslation('passport', lang)}>
                  <SymbolLine>{data?.passport}</SymbolLine>
                </GridFieldItem>
              )}
              <GridFieldItem label={getTranslation('passportMask', lang)}>
                {data?.passportRuMask}
              </GridFieldItem>
              {!isEmpty(data.directVaccinationInfo) && (
                <>
                {!isEmpty(data?.directVaccinationInfo?.expiredAt) && (
                  <GridFieldItem label={getTranslation('vaccinationExpiredAt', lang)}>
                    <PseudoBox>{getDateReadFormat(data?.directVaccinationInfo?.expiredAt)}</PseudoBox>
                  </GridFieldItem>
                )}
                  <GridFieldItem label={getTranslation('fio', lang)}>
                    {!isEmpty(data?.directVaccinationInfo?.fio) && data?.directVaccinationInfo?.fio[lang]}
                  </GridFieldItem>
                  <GridFieldItem label={getTranslation('vaccinationNumber', lang)}>
                    {data?.directVaccinationInfo?.number}
                  </GridFieldItem>
                  <GridFieldItem label={getTranslation('vaccinationSeries', lang)}>
                    {data?.directVaccinationInfo?.vaccinationSeries}
                  </GridFieldItem>
                  <GridFieldItem label={getTranslation('vaccinationStaffName', lang)}>
                    {!isEmpty(data?.directVaccinationInfo?.vaccinationStaffName) && data?.directVaccinationInfo?.vaccinationStaffName[lang]}
                  </GridFieldItem>
                </>
              )}
            </Grid>
          </PseudoBox>
          <PseudoBox textAlign="center" mt={4} fontSize="0.9rem" padding="0 20px" color="gray.400">
            {getTranslation('footerInfo', lang)} <br/> https://check.nocovid.su
          </PseudoBox>
        </>
      ) : (
        <Flex height="150px" align="center" justify="center">
          <Spinner color="blue.500" />
        </Flex>
      )}
    </Container>
  );
};

