import React from 'react';
import { PseudoBox, Text } from '@chakra-ui/core';
import { isEmpty } from 'lodash';

export const GridFieldItem = ({label, children, ...props}) => {
  if (isEmpty(children)) {
    return null;
  }
  return (
    <PseudoBox padding="12px 0" borderTopWidth={1} {...props}>
      <Text fontSize="0.9rem" color="gray.400" mb="4px">{label}</Text>
      <PseudoBox lineHeight="1.4">{children}</PseudoBox>
    </PseudoBox>
  );
};
