import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Theme } from './theme';
import {
    BrowserRouter as Router
} from "react-router-dom";

ReactDOM.render(
    <React.Fragment>
        <Theme>
            <Router>
                <App/>
            </Router>
        </Theme>
    </React.Fragment>,
    document.getElementById('root')
);
