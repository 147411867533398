export const text = {
    ru: {
        pageTitle: 'Проверка результатов',
        pageTitleVaccination: 'Проверка вакцинации',
        pageTitleCertificate: 'Проверка сертификата',
        pageSubTitle: 'тестирования РНК коронавируса SARSCoV-2',
        checkError: 'Не удалось подтвердить данные',
        resultNull: 'Нет данных',
        resultTrue: 'Положительный',
        resultFalse: 'Отрицательный',
        fieldTestDate: 'Дата исследования',
        fieldPassport: 'Паспорт (последние цифры)',
        fieldInsuranceNumber: 'Страховой полис',
        fieldInsuranceDate: 'действует до',
        fieldLaboratoryName: 'Наименование лаборатории',
        fieldLaboratoryTelephone: 'Телефон лаборатории',
        fieldLaboratoryAddress: 'Адрес лаборатории',
        footerInfo: 'Проверка проведена сервисом',
        vaccinationCompletedTrue: 'Вакцинация пройдена',
        vaccinationCompletedFalse: 'Вакцинация пройдена частично',
        vaccination: 'Вакцинация',
        vaccinationDate: 'Дата вакцинации',
        vaccinationName: 'Наименование вакцины',
        vaccinationNumber: 'Номер сертификата',
        vaccinationPhases: 'Фазы',
        vaccinationCertificate: 'Сертификат вакцинации от COVID-19',
        vaccinationExpiredAt: 'Срок действия вакцинации',
        vaccinationSeries: 'Серия вакцины',
        vaccinationStaffName: 'Наименование',
        dosesHospital: 'Организация',
        dosesName: 'Наименование',
        dosesDate: 'Дата вакцинации',
        passport: 'Паспорт',
        passportMask: 'Маскированный паспорт',
        dosesSeries: 'Серия',
        fio: 'ФИО',
        recoveredDate: 'Дата выздоровления',
        recoveredPersonCode: 'Код переболевшего',
        pageTitleRecovered: 'Проверка кода переболевшего',
        certificateCovid: 'Сертификат COVID-19'
    },
    en: {
        pageTitle: 'Checking results',
        pageTitleVaccination: 'VaccinationId check',
        pageTitleCertificate: 'Certificate check',
        pageSubTitle: 'RNA testing SARSCoV-2',
        checkError: 'Confirm data failing',
        resultNull: 'No data',
        resultTrue: 'Positive',
        resultFalse: 'Negative',
        fieldTestDate: 'Analysis date',
        fieldPassport: 'Passport (last numbers)',
        fieldInsuranceNumber: 'Insurance policy',
        fieldInsuranceDate: 'valid until',
        fieldLaboratoryName: 'Laboratory name',
        fieldLaboratoryTelephone: 'Laboratory phone',
        fieldLaboratoryAddress: 'Laboratory address',
        footerInfo: 'Checked by the service',
        vaccination: 'Vaccination',
        vaccinationCompletedTrue: 'VaccinationId passed',
        vaccinationCompletedFalse: 'VaccinationId partially completed',
        vaccinationDate: 'VaccinationId date',
        vaccinationNumber: 'Certificate number',
        vaccinationName: 'VaccinationId Name',
        vaccinationExpiredAt: 'Valid until',
        vaccinationPhases: 'Phases',
        dosesHospital: 'Hospital',
        vaccinationCertificate: 'COVID-19 vaccination certificate',
        dosesName: 'Name',
        passport: 'Passport',
        vaccinationSeries: 'Vaccination Series',
        vaccinationStaffName: 'Staff Name',
        passportMask: 'Passport mask',
        dosesDate: 'VaccinationId date',
        dosesSeries: 'Series',
        fio: 'Full name',
        recoveredPersonCode: 'Recovered person code',
        recoveredDate: 'Growth date',
        pageTitleRecovered: 'Checking recovered person code',
        certificateCovid: 'Certificate COVID-19'
    }
}
