import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Check } from './check';
import { Gosuslugi } from './gosuslugi';
import { Alert, PseudoBox, Text } from '@chakra-ui/core';
import { getLanguage } from './utils/getLanguage';
import { getTranslation } from './utils/getTranslation';
import { CheckCode } from './check-code';
import { Vaccination } from './vaccination';
import { VaccinationId } from './vaccinationId';
import { Recovered } from './recovered';

export const getUrlParameter = (name, search) => {
  const params = new URLSearchParams(search);
  return params.get(name);
};

function App() {
  const lang = getLanguage();
  const url = document.location.search;
  return (
    <Switch>
      <Route path="/vaccination" exact>
        <Vaccination/>
      </Route>
      <Route path="/vaccination/id" exact>
        <VaccinationId/>
      </Route>
      <Route path="/gosuslugi/:id" exact>
        <Gosuslugi/>
      </Route>
      <Route path="/recovered/:codeParam" exact>
        <Recovered/>
      </Route>
      <Route path="/:id" exact>
        <Check/>
      </Route>
      <Route path="/:id/:langUrl" exact>
        <Check/>
      </Route>
      <Route path="/">
        {url ? <CheckCode url={url}/> : (
          <>
            <Alert
              status="error"
              borderRadius={5}
              alignItems="center"
              flexDirection="column"
              textAlign="center"
              lineHeight="1.2"
            >
              <Text> {getTranslation('checkError', lang)}</Text>
            </Alert>
            <PseudoBox textAlign="center" mt={4} fontSize="0.9rem" padding="0 20px" color="gray.400">
              {getTranslation('footerInfo', lang)} <br/> https://check.nocovid.su
            </PseudoBox>
          </>
        )}
      </Route>
    </Switch>
  );
}

export default App;
