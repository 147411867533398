import React from 'react';
import { PseudoBox } from '@chakra-ui/core';

export const Container = ({ children }) => {
    return (
        <PseudoBox
            maxWidth="420px"
            marginLeft="auto"
            marginRight="auto"
            padding="30px 20px"
        >
            {children}
        </PseudoBox>
    );
};
