import { allowLang } from '../config';

const config = {
    'language': 'ru',
    'country': 'RU'
}

export const client = window.navigator ? (window.navigator.language ||
    window.navigator.systemLanguage ||
    window.navigator.userLanguage) : (config.language + "-" + config.country);

export const getSystemLanguage = () => {
    const lang = (client.search('-') > 0) ?
        client.substring(0, client.search('-')).toLowerCase() :
        client.toLowerCase();
    return allowLang.includes(lang) ? lang : config.language
};

export const getCountry = () => (client.search('-') > 0) ?
    client.substring(client.search('-') + 1, client.length).toLowerCase() :
    config.country;

export const getLanguage = (langUrl, testLangId) => {
    if (testLangId) {
        return testLangId
    } else {
        if (langUrl && allowLang.includes(langUrl)) {
            return langUrl
        } else {
            return getSystemLanguage();
        }
    }
};
