import React from 'react';
import { Text } from '@chakra-ui/core';
import { Alert as ChakraAlert } from '@chakra-ui/core';

export const Alert = ({ children }) => {
    return (
        <ChakraAlert
            status="error"
            borderRadius={5}
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            lineHeight="1.2"
        >
            <Text>{children}</Text>
        </ChakraAlert>
    );
};
