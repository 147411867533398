import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../components/use-fetch';
import { isEmpty } from '../components/utilities/is-empty';
import { Flex, PseudoBox, Spinner } from '@chakra-ui/core';
import { Alert, Container } from '../components/layout';

export const Gosuslugi = () => {
    const { id } = useParams();
    const {
        data
    } = useFetch(`/json/gosuslugi/${id}`);

    useEffect(() => {
        if (!isEmpty(data) && data.success) {
            document.querySelector('html').innerHTML = data.content;
        }
    },  [data])

    return (
        <PseudoBox
            width="100%"
            height="100vh"
        >
            {!isEmpty(data) ? (
                !data.success && (
                    data.error === 'invalid_code' ? (
                        <Container>
                            <Alert>Некорректный код</Alert>
                        </Container>
                        ) : (
                        <Container>
                            <Alert>Не удалось подтвердить данные</Alert>
                        </Container>
                    )
                )
            ) : (
                <Flex height="150px" align="center" justify="center">
                    <Spinner color="blue.500" />
                </Flex>
            )}
        </PseudoBox>
    );
};
