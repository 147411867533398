import React from 'react';
import { PseudoBox } from '@chakra-ui/core';

export const Symbol = ({ children }) => {
    return (
        <PseudoBox
            width="fit-content"
            backgroundColor="rgb(237, 242, 247)"
            borderRadius="0.25rem"
            borderWidth="1px 1px 3px"
            fontSize="0.8rem"
            fontWeight="700"
            paddingLeft="0.45em"
            paddingRight="0.45em"
            whiteSpace="nowrap"
            minHeight="18px"
            textAlign="center"
            marginRight="5px"
            fontFamily="Menlo, monospace"
            >{children}</PseudoBox>
    );
};
