import React from 'react';
import { Container } from '../components/layout';
import { Flex, Grid, Image, PseudoBox, Spinner, Text } from '@chakra-ui/core';
import Logo from '../image/logo512.png';
import { getTranslation } from '../utils/getTranslation';
import { getSystemLanguage } from '../utils/getLanguage';
import QRCode from 'qrcode.react';
import { isEmpty } from '../components/utilities/is-empty';
import { useFetch } from '../components/use-fetch';
import { GridFieldItem } from '../components/grid-field-item';
import { SymbolLine } from '../components/symbol-line';
import { getDateReadFormat } from '../components/utilities/date-formatting';
import { useParams } from 'react-router-dom';

export const Recovered = () => {
  const { codeParam } = useParams();
  const lang = getSystemLanguage();
  const {
    data
  } = useFetch(`/json/recovered?code=${codeParam}`);

  return (
    <Container>
      <Flex flexDirection="column" alignItems="center" mb={6}>
        <Image src={Logo} width="54px" height="54px" mb={2} alt=""/>
        <PseudoBox lineHeight="1.5" textAlign="center">
          <Text fontWeight="bold" fontSize="1.2rem">{getTranslation('pageTitleRecovered', lang)}</Text>
        </PseudoBox>
      </Flex>
      {!isEmpty(data) ? (
        <>
          <PseudoBox borderRadius={6} borderWidth={1} padding="20px 20px 5px 20px">
            <Flex justifyContent="center" padding="20px 10px 30px">
              <QRCode
                size={200}
                fgColor={'#276749'}
                value={window.location.href}
              />
            </Flex>
            <Grid>
              <PseudoBox padding="12px 0">
                <Text fontSize="0.9rem" color="gray.400" mb="2px">{getTranslation('recoveredDate', lang)}</Text>
                <PseudoBox>{getDateReadFormat(data.recoveredDate)}</PseudoBox>
              </PseudoBox>
              <GridFieldItem label={getTranslation('fieldPassport', lang)}>
                <SymbolLine>{data.passport}</SymbolLine>
              </GridFieldItem>
            </Grid>
          </PseudoBox>
          <PseudoBox textAlign="center" mt={4} fontSize="0.9rem" padding="0 20px" color="gray.400">
            {getTranslation('footerInfo', lang)} <br/> https://check.nocovid.su
          </PseudoBox>
        </>
      ) : (
        <Flex height="150px" align="center" justify="center">
          <Spinner color="blue.500" />
        </Flex>
      )}
    </Container>
  );
};

